import React from 'react';

const TechnologyStack = () => {
    return (
        <div className="flex justify-center md:mt-24 mt-8 px-10">
            <div className="text-lg w-full max-w-5xl">
                <h3>
                    Наш технологический стек
                </h3>
                <div className="mt-12">
                    Ruby on Rails, PostgreSQL, GraphQL, React
                </div>
            </div>
        </div>
    )
};

export default TechnologyStack;
