import React, { useEffect, useState } from 'react';

import logo from '../../images/logo.svg'
import './index.css';

const Header = ({ activeElement }: { activeElement: string }) => {
  const [activeNav, setActiveNav] = useState('');

  useEffect(() => {
    setActiveNav(activeElement);
  }, [activeElement]);

  return (
      <div className="flex fixed w-full bg-black z-10 shadow-lg shadow-[#313131] h-20 items-center lg:px-10 px-6">
      <div className="w-[250px]">
        <a href="/">
          <img alt="logo" src={logo}/>
        </a>
      </div>
      <nav className="md:flex hidden justify-center w-4/5 lg:gap-16 gap-6">
        <a href="/#about" className={activeNav === '#about' ? 'active' : ''}
           onClick={(e) => setActiveNav('#about')}>О нас</a>
        <a href="/#directions" className={activeNav === '#directions' ? 'active' : ''}
           onClick={(e) => setActiveNav('#directions')}>Наши направления</a>
        <a href="/#principles-of-operation" className={activeNav === '#principles-of-operation' ? 'active' : ''}
           onClick={(e) => setActiveNav('#principles-of-operation')}>Принципы работы</a>
        <a href="/#partners" className={activeNav === '#partners' ? 'active' : ''}
           onClick={(e) => setActiveNav('#partners')}>Партнеры</a>
      </nav>
    </div>
  );
};

export default Header;
