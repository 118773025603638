import React from 'react';

import Carousel from '../Carousel';

const teamList = [
    {
        imageUrl: '/',
        description: 'ceo',
        position: 'Генеральный директор',
        name: 'Зиганшин Эльдар',
    },
    {
        imageUrl: '/',
        description: 'cfo',
        position: 'Финансовый директор',
        name: 'Зиганшина Наиля',
    },
    {
        imageUrl: '/',
        description: 'art director',
        position: 'Арт-директор',
        name: 'Дробышева Светлана',
    },
    {
        imageUrl: '/',
        description: 'project manager',
        position: 'Проектный менеджер',
        name: 'Фазлиева Диана',
    },
    {
        imageUrl: '/',
        description: 'chief administrator',
        position: 'Главный администратор',
        name: 'Коркина Алена',
    },
    {
        imageUrl: '/',
        description: 'lead developer',
        position: 'Ведущий программист',
        name: 'Дементьев Виталий',
    },
    {
        imageUrl: '/',
        description: 'frontend developer',
        position: 'Ведущий FrontEnd разработчик',
        name: 'Краснова Ольга',
    },
    {
        imageUrl: '/',
        description: 'backend developer',
        position: 'Ведущий BackEnd разработчик',
        name: 'Каримов Марат',
    },
];

const Team = () => {
    return (
        <div className="flex justify-center mt-32 px-10">
            <div className="text-lg w-full max-w-5xl">
                <h3>
                    Наша команда
                </h3>
                <div className="relative mt-20 px-6">
                    <Carousel>
                        {teamList.map(({ imageUrl, description, position, name }) => (
                            <div key={description}>
                                <div className="flex flex-col justify-center items-center gap-2 text-xl px-[15px] text-center">
                                    <div className="w-[142px] h-[142px] rounded-full">
                                        <img src={imageUrl} alt={description} />
                                    </div>
                                    <span className="green-text">{position}</span>
                                    <div className="w-[40px] h-[4px] bg-white mt-1"></div>
                                    <span className="font-bold">{name}</span>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    )
};

export default Team;
