import React, { LegacyRef } from 'react';

import QuoteWrapper from '../QuoteWrapper';
import signature from '../../images/signature.png';

const directionsList = [
    'Разработка программного обеспечения',
    'Системная интеграция',
    'Разработка аналитических программ',
    'Создание чат ботов',
    'Поддержка и обслуживание IT-инфраструктуры',
    'Разработка и внедрение систем управления данными',
    'Искусственный интеллект и машинное обучение',
];

const Directions = ({ innerRef }: { innerRef: LegacyRef<HTMLDivElement> }) => {
    return (
        <div id="directions" ref={innerRef} className="flex justify-center bg-[url('images/man-bg.png')] 2xl:bg-cover bg-contain md:bg-[center_top_60%] bg-[center_top_70%] bg-no-repeat md:px-16 px-10 pt-36 md:mb-10">
            <div className="w-full max-w-5xl text-lg">
                <h2 className="w-full max-w-5xl">Наши направления в сфере информационных технологий (IT)</h2>
                <ul className="mt-12 md:ml-8 list-inside list-image-[url('images/rhombus-green.svg')]">
                    {directionsList.map((text, index) => (
                        <li key={index} className="mb-4">
                            <span className="pl-3">{text}</span>
                        </li>
                    ))}
                </ul>
                <div className="flex md:flex-row flex-col md:gap-0 gap-16 justify-between text-lg w-full max-w-5xl mt-44">
                    <QuoteWrapper className="md:max-w-[45%] max-w-md ml-6 md:mr-12 mr-6">
                        <p>
                            Изменения — это не угроза, а возможность для инноваций и улучшений
                        </p>
                    </QuoteWrapper>
                </div>
                <div className="flex w-full max-w-5xl md:mt-20 mt-10">
                    <div className="w-[170px] h-[84px]">
                        <img src={signature} alt="signature" />
                    </div>
                    <div>
                        <p>С Уважением, Генеральный директор </p>
                        <br/>
                        <p>Эльдар Зиганшин</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Directions;
