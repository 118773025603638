import React from 'react';

import './index.css';

const MainBanner = () => {
    return (
        <div className="main-banner">
            <h1 className="w-full max-w-[1100px]">
                Каждый клиент - это долгосрочное сотрудничество, а не случайная встреча
            </h1>
            <div className="flex md:justify-end md:mt-52 mt-32 md:px-0 px-10">
                <div className="citation text-right w-full md:max-w-[520px] relative md:mr-8">
                    <span className="font-bold text-lg">Генри Форд:</span>
                    <p className="mt-2 text-lg">
                        «Главная польза капитала не в том, чтобы сделать больше денег,
                        а чтобы делать деньги ради улучшения жизни»
                    </p>
                </div>
            </div>
        </div>
    )
};

export default MainBanner;
