import React from 'react';

import logoMedagregator from '../../images/logo-medagregator.svg';
import logoMedplatforma from '../../images/logo-medplatforma.svg';
import logoChatbot from '../../images/logo-chat-bot.svg';

const productsList = [
    {
        imageUrl: logoMedagregator,
        imageAlt: 'medagregator',
        label: 'Medagregator',
        url: 'https://medagregator.ru',
        description: '1-й медицинский B2B маркетплейс в формате публичной оферты',
    },
    {
        imageUrl: logoMedplatforma,
        imageAlt: 'medplatforma',
        label: 'Medplatforma',
        url: 'https://medplatforma.ru',
        description: 'сервис автоматизированных закупок медицинских товаров',
    },
    {
        imageUrl: logoChatbot,
        imageAlt: 'chat-bot',
        label: 'Здоров и точка | Бот',
        url: 'https://t.me/zdorov_i_tochka_bot',
        description: 'персональный бот-помощник для обслуживания и консультаций клиентов в рамках клуба Привилегий сети аптек',
    },
];

const Products = () => {
    return (
        <div className="flex justify-center md:mt-24 mt-8 px-10">
            <div className="text-lg w-full max-w-5xl">
                <h3>
                    Наши главные продукты
                </h3>
                <div className="flex flex-row md:justify-around justify-between items-start gap-6 md:mt-28 mt-16">
                    {productsList.map(({ imageUrl, imageAlt, label, url, description}) => (
                        <a href={url} key={label} target="_blank" rel="noreferrer" className="flex flex-col justify-center items-center gap-4 lg:w-[32%] lg:flex-[0_1_32%] w-[24%] flex-[0_1_24%]">
                            <div className="h-[124px]">
                                <img src={imageUrl} alt={imageAlt}/>
                            </div>
                            <div className="md:w-[69px] md:h-[9px] w-[50px] h-[4px] bg-white md:mt-10 mt-6"></div>
                            <span className="lg:text-2xl sm:text-lg text-center text-sm font-bold">{label}</span>
                            <span className="text-center text-sm">{description}</span>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
};

export default Products;
