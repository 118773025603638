import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Header from './components/Header';
import MainBanner from './components/MainBanner';
import About from './components/About';
import Directions from './components/Directions';
import PrinciplesOfOperation from './components/PrinciplesOfOperation';
import Products from './components/Products';
import TechnologyStack from './components/TechnologyStack';
import PriceList from './components/PriceList';
import Team from './components/Team';
import Partners from './components/Partners';
import Footer from './components/Footer';

function App() {
    const { ref: aboutRef, inView: aboutInView } = useInView();
    const { ref: directionsRef, inView: directionsInView } = useInView();
    const { ref: principlesRef, inView: principlesInView } = useInView();
    const { ref: partnersRef, inView: partnersInView } = useInView();

    const [activeElement, setActiveElement] = useState('')

  useEffect(() => {
    if (aboutInView) {
      setActiveElement('#about');
    } else if (directionsInView) {
      setActiveElement('#directions');
    } else if (principlesInView) {
      setActiveElement('#principles-of-operation');
    } else if (partnersInView) {
      setActiveElement('#partners');
    }
  }, [aboutInView, directionsInView, principlesInView, partnersInView]);

    return (
        <>
            <Header activeElement={activeElement} />
            <MainBanner />
            <About innerRef={aboutRef} />
            <Directions innerRef={directionsRef} />
            <PrinciplesOfOperation innerRef={principlesRef} />
            <Products />
            <TechnologyStack />
            <PriceList />
            <Team />
            <Partners innerRef={partnersRef} />
            <Footer />
        </>
    );
}

export default App;
