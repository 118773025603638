import React from 'react';

import './index.css';

const QuoteWrapper = ({ children, className = '' }: { children: React.ReactNode, className?: string }) => {
    return (
        <div className={`quote ${className}`}>
            {children}
            <p className="empty-element"></p>
        </div>
    )
};

export default QuoteWrapper;
