import React, {useState} from 'react';
import logo from '../../images/logo.svg'
import {ReactComponent as TelegramSvg} from '../../images/telegram.svg';
import {ReactComponent as WhatsappSvg} from '../../images/whatsapp.svg';

const phoneRegularExp = /^(\+7|8)?(?:[-()\s]*\d){10}$/;

const Footer = () => {
  const year = new Date().getFullYear();

  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [showError, setShowError] = useState<boolean>(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPhone(value);

    if (value.match(phoneRegularExp) || value === '') {
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  return (<footer className="flex flex-col items-center md:bg-[#171717] px-8 lg:pt-6 pt-12 lg:pb-8 pb-16 mt-28 relative">
    <div className="xl:mb-32 mb-16 w-full max-w-7xl xl:px-28 flex flex-col items-center">
      <div className="flex lg:flex-row flex-col items-center lg:gap-3 gap-8 w-full lg:max-w-full max-w-[450px]">
        <div className="text-2xl font-bold md:shrink-0 md:w-[390px] text-center">ВОЗНИКЛИ К НАМ ВОПРОСЫ?</div>
        <div className="block h-[55px] lg:w-[32%] w-full">
          <input
            type="text"
            value={phone}
            placeholder="Телефон"
            onChange={onChange}
            className={`block h-full w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-black ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#9D8DDC] ${showError ? 'ring-red-600 focus:ring-red-600' : ''} text-lg`}
          />
          {showError && (<span className="text-red-600">
                                Некорректный номер телефона
                            </span>)}
        </div>
        <button
          className="md:shrink-0 text-xl rounded-md bg-[#17FF76] hover:bg-[#5CF79C] text-black h-[55px] lg:w-[310px] w-full">ПОЛУЧИТЬ
          КОНСУЛЬТАЦИЮ
        </button>
      </div>
      <div
        className="xl:absolute flex items-center md:gap-6 gap-12 right-8 top-8 justify-center xl:mt-0 mt-16">
        <a href="https://wa.me/79393686557" target="_blank" rel="noreferrer" title="WhatsApp">
          <WhatsappSvg className="md:w-[37px] md:h-[37px] w-[56px] h-[56px]"/>
        </a>
        <a href="https://t.me/+79393686557" target="_blank" rel="noreferrer" title="Telegram">
          <TelegramSvg className="md:w-[37px] md:h-[37px] w-[56px] h-[56px]"/>
        </a>
      </div>
    </div>
    <div className="xl:mb-32 mb-16 w-full max-w-7xl xl:px-28 flex flex-col">
      <div className="pb-8 xl:grid xl:grid-cols-2 xl:gap-8">
        <div className="grid lg:grid-cols-2 gap-8 xl:col-span-4">
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <div>
                <a href="/">
                  <img alt="logo" src={logo}/>
                </a>
              </div>
              {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
              <ul role="list" className="mt-4 space-y-4 cursor-default">
                <li>
                    <span className="text-base text-white">
                      © Все права защищены
                    </span>
                </li>
                <li>
                    <span className="text-base text-white">
                      АО “Медагрегатор” {year}
                    </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3
                className="text-sm font-semibold text-white tracking-wider uppercase">Реквизиты
              </h3>
              {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
              <ul role="list" className="mt-4 space-y-4 cursor-default">
                <li>
                    <span className="text-base text-white">
                        ИНН:&nbsp;1684016819
                    </span>
                </li>
                <li>
                    <span className="text-base text-white">
                        КПП:&nbsp;168401001
                    </span>
                </li>
                <li>
                    <span className="text-base text-white">
                        ОГРН:&nbsp;1231600065102
                    </span>
                </li>
                <li>
                    <span className="text-base text-white">
                        ОКПО:&nbsp;78345326
                    </span>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-white tracking-wider uppercase cursor-default">Адрес:</h3>
              {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
              <ul role="list" className="mt-4 space-y-4 cursor-default">
                <li>
                    <span className="text-base text-white">
                      Казань, ул. Карла Маркса 49, 3 этаж
                    </span>
                </li>
                <li>
                  <a href={"tel:+7 939-368-65-57"} className="text-sm lg:text-lg font-semibold text-white">
                    +7 939-368-65-57
                  </a>
                </li>
                <li>
                  <a href={"mailto:help@medagregator.ru"} className="text-sm lg:text-lg font-semibold text-white">
                    help@medagregator.ru
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>)
};

export default Footer;
