import React, { LegacyRef } from 'react';

import Carousel from '../Carousel';
import nefromedLogo from '../../images/nefromed.png';
import stomusLogo from '../../images/stomus.png';
import zdorovie7iLogo from '../../images/zdorovie7i.png';

const partnersList = [
    {
        imageUrl: nefromedLogo,
        description: 'nefromed',
    },
    {
        imageUrl: stomusLogo,
        description: 'stomus',
    },
    {
        imageUrl: zdorovie7iLogo,
        description: 'zdorovie7i',
    },
];

const Partners = ({ innerRef }: { innerRef: LegacyRef<HTMLDivElement> }) => {
    const carouselSettings = {
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
    };

    return (
        <div id="partners" ref={innerRef} className="flex justify-center pt-28 px-10">
            <div className="text-lg w-full max-w-5xl">
                <h3>Наши партнеры</h3>
                <div className="mt-20 px-6">
                    <Carousel customSettings={carouselSettings}>
                        {partnersList.map(({ imageUrl, description }) => (
                            <div className="px-4" key={description}>
                                <img src={imageUrl} alt={description}/>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    )
};

export default Partners;
