import React from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ReactComponent as CornerSvg } from '../../images/corner.svg';

const PrevArrow = (props: { className?: string, style?: object }) => {
    const { className, style, ...other } = props;
    const disable = className?.split(' ').includes('slick-disabled');

    return (
        <CornerSvg
            {...other}
            className={`${className} ${disable ? 'opacity-20' : ''}`}
            style={{
                ...style,
                transform: 'rotate(-45deg)',
            }}
        />
    )
};

const NextArrow = (props: { className?: string, style?: object }) => {
    const { className, style, ...other } = props;
    const disable = className?.split(' ').includes('slick-disabled');

    return (
        <CornerSvg
            {...other}
            className={`${className} ${disable ? 'opacity-20' : ''}`}
            style={{
                ...style,
                transform: 'rotate(135deg)',
            }}
        />
    )
};

const Carousel = ({ children, customSettings }: { children: React.ReactNode, customSettings?: object }) => {
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ],
        ...customSettings,
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
                {children}
            </Slider>
        </div>
    )
};

export default Carousel;
