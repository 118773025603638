import React from 'react';

import codeImg from '../../images/code.svg';
import settingsImg from '../../images/settings.svg';
import serviceImg from '../../images/service.svg';
import userImg from '../../images/user.svg';

import './index.css';

const priceList = [
    {
        imageUrl: codeImg,
        title: 'Разработка программного обеспечения',
        imageDescription: 'Разработка программного обеспечения',
        description: (
            <>
                <div>
                    Индивидуальное ПО:
                    <br/>
                    от 100 000 руб.
                </div>
                <div>
                    Мобильные приложения:
                    <br/>
                    от 80 000 руб.
                </div>
                <div>
                    Веб-решения:
                    <br/>
                    от 60 000 руб.
                </div>
            </>
        )
    },
    {
        imageUrl: settingsImg,
        title: (
            <>Консалтинг и <br /> аудит</>
        ),
        imageDescription: 'Консалтинг и аудит',
        description: (
            <>
                <div>
                    IT-аудит:
                    <br/>
                    от 30 000 руб.
                </div>
                <div>
                    Консультации:
                    <br/>
                    от 15 000 руб./час
                </div>
                <div>
                    Разработка стратегии:
                    <br/>
                    от 50 000 руб.
                </div>
            </>
        )
    },
    {
        imageUrl: serviceImg,
        title: 'Поддержка и обслуживание',
        imageDescription: 'Поддержка и обслуживание',
        description: (
            <>
                <div>
                    Техническая поддержка:
                    <br/>
                    от 10 000 руб./мес.
                </div>
                <div>
                    Обслуживание серверов:
                    <br/>
                    от 20 000 руб./мес.
                </div>
                <div>
                    Настройка сетевой инфраструктуры:
                    <br/>
                    от 15 000 руб.
                </div>
            </>
        )
    },
];

const PriceList = () => {
    return (
        <div id="price-list" className="flex justify-center md:mt-32 mt-16 px-10">
            <div className="text-lg w-full max-w-5xl">
                <h3>Прайс-лист</h3>
                <p className="mt-10">
                    Добро пожаловать в наш прайс-лист!
                    Мы рады предложить вам широкий спектр IT-услуг, разработанных для поддержки и развития вашего бизнеса.
                    Наша команда экспертов готова помочь вам на каждом этапе — от идеи до реализации.
                </p>

                <div className="grid md:grid-cols-4 grid-cols-3 gap-4 mt-16">
                    {priceList.map(({ imageUrl, title, imageDescription, description }) => (
                        <div key={imageDescription} className="price-item">
                            <div>
                                <img src={imageUrl} alt={imageDescription} />
                            </div>
                            <div className="lg:text-2xl sm:text-xl text-lg font-bold">{title}</div>
                            <div className="flex flex-col gap-4 text-sm">
                                {description}
                            </div>
                        </div>
                    ))}
                    <div className="price-item special">
                        <div>
                            <img src={userImg} alt="Специальные предложения" />
                        </div>
                        <div className="lg:text-2xl sm:text-xl text-lg font-bold">Специальные предложения</div>
                        <div className="flex flex-col gap-4 text-sm col-span-2 md:col-span-1">
                            <div>
                                Пакеты для стартапов:
                                <br/>
                                от 150 000 руб.
                            </div>
                            <div>
                                Скидки для постоянных:
                                <br/>
                                клиентов: до 20%
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-16">
                    <span className="green-text text-4xl font-bold">!</span>
                    <p>
                        Цены указаны без учета НДС. Для получения индивидуального предложения и более детальной
                        информации, пожалуйста, свяжитесь с нами.
                    </p>
                </div>
            </div>
        </div>
    )
};

export default PriceList;
