import React, { LegacyRef } from 'react';

import './index.css';

const About = ({ innerRef }: { innerRef: LegacyRef<HTMLDivElement> }) => {
    return (
        <div id="about" ref={innerRef} className="relative flex justify-center md:px-16 pt-20 md:mb-24 mb-2 px-10">
            <div className="about-text">
                <h2 className="mb-5">О нас</h2>
                <div className="w-full max-w-3xl">
                    <p>АО Медагрегатор основан в 2019 г.</p>
                    <p>
                        Мы совершенствуемся, выбирая новые направления развития, принося свой
                        вклад в развитие IT-отрасли России.
                    </p>
                    <br/>
                    <p>
                        Деятельность полностью соответствует действующему законодательству РФ, а также
                        внутренним нормативным документам компании.
                    </p>
                </div>
            </div>
            <div className="hidden absolute md:flex flex-row items-center gap-2 right-[-6rem] translate-y-[-50%] top-[50%] -rotate-90 green-text">
                <span className="w-12 h-0.5 bg-[#17FF76] block"></span>
                МА основан в 2019
                <span className="w-12 h-0.5 bg-[#17FF76] block"></span>
            </div>
        </div>
    );
};

export default About;
