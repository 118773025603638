import React, { LegacyRef } from 'react';

const principlesList = [
    'Клиентоориентированность',
    'Командная работа',
    'Фокус на результат',
    'Инновации',
    'Непрерывное обучение',
    'Открытость и прозрачность',
    'Аналитика и данные',
];

const PrinciplesOfOperation = ({ innerRef }: { innerRef: LegacyRef<HTMLDivElement> }) => {
    return (
        <div id="principles-of-operation" ref={innerRef} className="bg-[url('images/banner-bg.png')] bg-contain bg-no-repeat bg-left-bottom flex flex-col items-center md:pt-18 pt-10 pb-16 px-10">
            <div className="text-lg w-full max-w-5xl mt-20">
                <h3>Принципы работы:</h3>
                <ul className="md:mt-12 mt-8 md:ml-8 list-inside list-image-[url('images/rhombus-violet-sm.svg')]">
                    {principlesList.map((text, index) => (
                        <li key={index} className="mb-4">
                            <span className="pl-3">{text}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
};

export default PrinciplesOfOperation;
